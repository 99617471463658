export default async function ({ route, store, $axios, redirect }) {
    if (store.state.isWindowActive === false) {
        return
    }

    let token = route.query.token
    if (!token) {
        token = route.params.token
    }

    if (!token) {
        throw Error('No query token')
    }

    const stepResponse = await $axios.get(`/orders/${token}/step`)
    const flowStep = stepResponse.data
    store.commit('setFlowStep', flowStep)

    if (route.path.includes(`${flowStep.layout}/${flowStep.step}`)) {
        return
    }

    if (!flowStep.layout) {
        return redirect(`/${flowStep.step}?token=${token}`)
    }

    return redirect(`/${flowStep.layout}/${flowStep.step}?token=${token}`)
}
